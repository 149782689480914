import analytics from '@capturi/analytics'
import { ListCase } from '@capturi/api-cases'
import { TextFilterValues } from '@capturi/api-filters'
import { useCurrentUser } from '@capturi/core'
import { Period, useFilterPeriodContext } from '@capturi/filters'
import { ResponseError } from '@capturi/request'
import { ContentPlaceholder, List } from '@capturi/ui-components'
import { Box, Button, Flex } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import {
  InfiniteData,
  InfiniteQueryObserverResult,
} from '@tanstack/react-query'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import isEqual from 'react-fast-compare'
import { useNavigate, useParams } from 'react-router'
import { useEffectOnce, useLocalStorage } from 'react-use'

import routes from '../routes'
import CaseListItem from './CaseListItem'
import CaseRowSkeleton from './CaseListItemSkeleton'

const CaseList: React.FC<{
  cases: InfiniteData<ListCase[]> | undefined
  isFetchingCases: boolean
  fetchNextPage:
    | (() => Promise<
        InfiniteQueryObserverResult<
          InfiniteData<ListCase[], unknown>,
          ResponseError
        >
      >)
    | null
  isFetchingNextPage: boolean
  selectedCaseUid?: string
  filter: TextFilterValues
  onCaseSelected: (caseUid: string) => void
}> = ({
  cases,
  isFetchingCases,
  fetchNextPage,
  isFetchingNextPage,
  filter,
  selectedCaseUid,
  onCaseSelected,
}) => {
  const navigate = useNavigate()
  const currentUser = useCurrentUser()
  const { uid: conversationUidParam } = useParams()

  const ref = useRef<HTMLDivElement>(null)

  const [prevFilter, setPrevFilter] = useState<TextFilterValues>(filter)

  const { period, periodDef } = useFilterPeriodContext()
  const [prevPeriod, setPrevPeriod] = useState<Period>(period)

  const [lastSelectedCase, setLastSelectedCase] = useLocalStorage<string>(
    `lastSelectedCase_${currentUser.organizationId}`,
  )

  const handleCaseSelected = useCallback(
    (uid: string): void => {
      if (uid) {
        onCaseSelected(uid)
        React.startTransition(() => {
          navigate({
            pathname: routes.case(uid),
            search: new URLSearchParams({ period: periodDef.name }).toString(),
          })
        })
      }
    },
    [navigate, onCaseSelected, periodDef.name],
  )
  //each time the selected tracker changes, we update local storage
  useEffect(() => {
    if (
      conversationUidParam != null &&
      conversationUidParam !== '' &&
      lastSelectedCase !== conversationUidParam
    ) {
      setLastSelectedCase(conversationUidParam)
    }
  }, [conversationUidParam, lastSelectedCase, setLastSelectedCase])

  useEffectOnce(() => {
    if (selectedCaseUid == null && conversationUidParam != null) {
      onCaseSelected(conversationUidParam)
    }
  })

  const firstCaseUid = cases?.pages[0][0]?.uid
  useEffect(() => {
    if (!firstCaseUid) return

    if (!selectedCaseUid) {
      // reselect first case if list loaded and nothing was selected before
      handleCaseSelected(firstCaseUid)
      if (ref.current) ref.current.scrollTop = 0
    } else if (!isEqual(filter, prevFilter)) {
      setPrevFilter(filter)
      handleCaseSelected(firstCaseUid)
    } else if (!isEqual(prevPeriod, period)) {
      setPrevPeriod(period)
      handleCaseSelected(firstCaseUid)
    }
  }, [
    filter,
    prevFilter,
    firstCaseUid,
    handleCaseSelected,
    prevPeriod,
    period,
    selectedCaseUid,
  ])

  const isEmpty = cases?.pages?.length === 0

  const handleLoadMoreClicked = useCallback((): void => {
    fetchNextPage?.()
    analytics.event('casesList_loadMore')
  }, [fetchNextPage])

  if (isEmpty) {
    return (
      <ContentPlaceholder.Container mt={2}>
        <ContentPlaceholder.Heading fontSize="lg">
          <Trans>No results</Trans>
        </ContentPlaceholder.Heading>
        <ContentPlaceholder.Body fontSize="sm">
          <Trans>
            Try to increase the period up in the right corner or make your
            search terms broader
          </Trans>
        </ContentPlaceholder.Body>
      </ContentPlaceholder.Container>
    )
  }

  return (
    <Box id="container" w="100%" h="100%" position="relative">
      <Box h="100%" overflowY="auto">
        <Box h="100%" mr={2}>
          <List
            py={0}
            overflowX="hidden"
            borderX="1px solid"
            borderColor="gray.200"
          >
            {!cases ? (
              <>
                <CaseRowSkeleton />
                <CaseRowSkeleton />
                <CaseRowSkeleton />
                <CaseRowSkeleton />
                <CaseRowSkeleton />
                <CaseRowSkeleton />
                <CaseRowSkeleton />
              </>
            ) : (
              (cases?.pages ?? []).map((d) =>
                d.map((c) => (
                  <CaseListItem
                    key={c.uid}
                    caseUid={c.uid}
                    dateTime={c.created}
                    messages={c.messageTypes}
                    isSelected={c.uid === selectedCaseUid}
                    title={c.inbox}
                    userUids={c.users}
                    onSelectCase={handleCaseSelected}
                  />
                )),
              )
            )}
            <Flex my={4} justify="center" w="100%">
              {!(isFetchingCases || isEmpty) && (
                <Button
                  onClick={handleLoadMoreClicked}
                  isDisabled={!fetchNextPage || isFetchingNextPage}
                  isLoading={isFetchingNextPage}
                >
                  {fetchNextPage == null ? (
                    <Trans>No more conversations</Trans>
                  ) : (
                    <Trans>Load more</Trans>
                  )}
                </Button>
              )}
            </Flex>
          </List>
          <Box
            bottom={0}
            right={2}
            background="gray.200"
            position="sticky"
            h="1px"
            w="100%"
          />
        </Box>
      </Box>
      <Box
        top={0}
        right={2}
        background="gray.200"
        position="absolute"
        h="1px"
        w="100%"
      />
    </Box>
  )
}

export default CaseList
